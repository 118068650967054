import { reactive, computed } from "vue"
import { isObjectEmpty, getMonthDates } from "@/utils/helpers"
import DateFilterKeys from "@/components/BaseDateFilter/dateFilterKeys"
import { PROJECT_DROPDOWN_OPTIONS, MODAL_WINDOW_NAMES } from "@/constants"

const LOCAL_STORAGE_DASHBOARD_PROJECT = "current-dashboard-project"
const LOCAL_STORAGE_PROJECT = "current-project"
const LOCAL_STORAGE_DASHBOARD_USER = "current-dashboard-user"
const LOCAL_STORAGE_PROJECT_DROPDOWN_OPTION = "project-dropdown-option"
const LOCAL_STORAGE_PROJECT_DROPDOWN_SELECTION = "project-dropdown-selection"

const getStoredProject = (dashboardProject = false) => {
  const storedProject = localStorage.getItem(dashboardProject ? LOCAL_STORAGE_DASHBOARD_PROJECT : LOCAL_STORAGE_PROJECT)
  if (storedProject) {
    return JSON.parse(storedProject)
  } else {
    return null
  }
}

const getStoredProjectDropdownOptions = () => {
  const storedProjectDropdownOption = localStorage.getItem(LOCAL_STORAGE_PROJECT_DROPDOWN_OPTION)
  if (storedProjectDropdownOption) {
    return storedProjectDropdownOption
  } else {
    return PROJECT_DROPDOWN_OPTIONS.ALL
  }
}

const getStoredProjectDropdownSelection = () => {
  const storedProjectDropdownSelection = localStorage.getItem(LOCAL_STORAGE_PROJECT_DROPDOWN_SELECTION)
  if (storedProjectDropdownSelection) {
    return JSON.parse(storedProjectDropdownSelection)
  } else {
    return []
  }
}

const getStoredUser = () => {
  const storedUser = localStorage.getItem(LOCAL_STORAGE_DASHBOARD_USER)
  if (storedUser) {
    return JSON.parse(storedUser)
  } else {
    return null
  }
}

const state = reactive({
  snackbarMessage: null,
  openedModalWindow: null,
  currentDashboardUser: getStoredUser(),
  currentDashboardProject: getStoredProject(true),
  currentProject: getStoredProject(),
  currentActivitiesPeriod: {
    dashboard: { dates: [null, null], name: DateFilterKeys.ALL },
    projects: { dates: [null, null], name: DateFilterKeys.ALL },
    deals: { dates: [null, null], name: DateFilterKeys.ALL },
    customers: { dates: [null, null], name: DateFilterKeys.ALL }
  },
  currentActivitiesStatus: {
    dashboard: { status: null },
    projects: { status: null },
    deals: { status: null },
    customers: { status: null }
  },
  currentDashboardPeriod: { dates: getMonthDates(), name: DateFilterKeys.MONTH },
  currentProjectPeriod: { dates: getMonthDates(), name: DateFilterKeys.MONTH },
  projectDropdownOption: getStoredProjectDropdownOptions(),
  projectDropdownSelection: getStoredProjectDropdownSelection(),
  showClosedProjects: false,
  disabledHistoricalCheckboxMessage: null,
  isSidebarExpanded: localStorage.getItem("sidebar-expanded") === "true",
  exceptionalLoadingCount: 0,
  exceptionalUpdatingCount: 0,
  tablesSettings: {}
})

const methods = {
  // TODO: Include settings in url params/query. Don't remove it from this store module
  setTablePagination({ tableName, pagination = null }) {
    if (!state.tablesSettings[tableName]) state.tablesSettings[tableName] = {}

    if (!isObjectEmpty(pagination)) {
      state.tablesSettings[tableName].pagination = pagination
    } else if (Object.prototype.hasOwnProperty.call(state.tablesSettings[tableName], "pagination")) {
      delete state.tablesSettings[tableName].pagination
    }
  },

  setTableSearch({ tableName, string = "" }) {
    if (!state.tablesSettings[tableName]) {
      state.tablesSettings[tableName] = {}
    }

    const hasActiveFilters =
      !!state.tablesSettings[tableName].filter && !isObjectEmpty(state.tablesSettings[tableName].filter)

    if (string) {
      // User typed or clicked Search (non-empty)
      // Only store originalPagination if we have no filters
      if (
        !state.tablesSettings[tableName].originalPagination &&
        state.tablesSettings[tableName].pagination &&
        !hasActiveFilters
      ) {
        state.tablesSettings[tableName].originalPagination = {
          ...state.tablesSettings[tableName].pagination
        }
      }

      // Page=1 for new search
      if (state.tablesSettings[tableName].pagination && state.tablesSettings[tableName].pagination.page !== 1) {
        state.tablesSettings[tableName].pagination.page = 1
      }

      state.tablesSettings[tableName].search = string
    } else {
      // Remove the search key
      if (Object.prototype.hasOwnProperty.call(state.tablesSettings[tableName], "search")) {
        delete state.tablesSettings[tableName].search
      }
      // User cleared the search
      if (state.tablesSettings[tableName].originalPagination) {
        // Only restore if there's no active filters
        if (state.tablesSettings[tableName].originalPagination && !hasActiveFilters) {
          state.tablesSettings[tableName].pagination = {
            ...state.tablesSettings[tableName].originalPagination
          }
          delete state.tablesSettings[tableName].originalPagination
        } else {
          // Else, revert to page=1 if we do have filters
          if (state.tablesSettings[tableName].pagination && state.tablesSettings[tableName].pagination.page !== 1) {
            state.tablesSettings[tableName].pagination.page = 1
          }
        }
      }
    }
  },

  setTableSorting({ tableName, columnKey, columnSortingSettings = null }) {
    if (!state.tablesSettings[tableName]) state.tablesSettings[tableName] = {}
    if (!state.tablesSettings[tableName].sort) state.tablesSettings[tableName].sort = {}

    if (!isObjectEmpty(columnSortingSettings)) {
      state.tablesSettings[tableName].sort[columnKey] = columnSortingSettings
    } else if (Object.prototype.hasOwnProperty.call(state.tablesSettings[tableName].sort, columnKey)) {
      delete state.tablesSettings[tableName].sort[columnKey]
    }
  },

  setTableFiltering({ tableName, columnKey, appliedOptions = [], isNullApplied = false }) {
    // When we filter we set paginator page to 1
    if (
      state.tablesSettings[tableName] &&
      !isObjectEmpty(state.tablesSettings[tableName].pagination) &&
      state.tablesSettings[tableName].pagination.page !== 1
    ) {
      state.tablesSettings[tableName].pagination.page = 1
    }

    if (!state.tablesSettings[tableName]) state.tablesSettings[tableName] = {}
    if (!state.tablesSettings[tableName].filter) state.tablesSettings[tableName].filter = {}

    if (state.tablesSettings[tableName].originalPagination) {
      delete state.tablesSettings[tableName].originalPagination
    }

    if (appliedOptions.length || isNullApplied) {
      state.tablesSettings[tableName].filter[columnKey] = { appliedOptions, isNullApplied }
    } else if (Object.prototype.hasOwnProperty.call(state.tablesSettings[tableName].filter, columnKey)) {
      delete state.tablesSettings[tableName].filter[columnKey]
    }
  },

  /** @param {?{message: string, type?: string, moreInfo?: string, link?: string}} messageObject */
  setSnackbarMessage(messageObject = null) {
    state.snackbarMessage = messageObject
  },

  /**
   * @param {?{name: string, title?: string, height?: number, payload?: Object}} openedModalWindow
   * @param { Boolean } skipConfirmation
   */
  setModalWindowState(openedModalWindow = null, skipConfirmation) {
    // Prevented closing certain windows through confirmation modal
    if (
      !skipConfirmation &&
      !openedModalWindow &&
      [MODAL_WINDOW_NAMES.DEAL_CREATION_MODAL].includes(state.openedModalWindow?.name)
    ) {
      const cachedModalSettings = state.openedModalWindow

      methods.setModalWindowState({
        name: MODAL_WINDOW_NAMES.WARNING_MESSAGE_MODAL,
        payload: {
          itemType: "Are you sure you want to exit deal wizard?",
          controlledWindowClosure: true,
          isConfirmPresent: true,
          closeButtonName: "Cancel",
          confirmAction: () => {
            state.openedModalWindow = null
            this.setSnackbarMessage()
          },
          cancelAction: () => {
            state.openedModalWindow = cachedModalSettings
          }
        }
      })
    } else {
      state.openedModalWindow = openedModalWindow
      this.setSnackbarMessage()
    }
  },

  setCurrentDashboardUser(user) {
    localStorage.setItem(LOCAL_STORAGE_DASHBOARD_USER, JSON.stringify(user))
    state.currentDashboardUser = user
  },

  setCurrentDashboardProject(project) {
    localStorage.setItem(LOCAL_STORAGE_DASHBOARD_PROJECT, JSON.stringify(project))
    state.currentDashboardProject = project
  },

  setCurrentProject(project) {
    localStorage.setItem(LOCAL_STORAGE_PROJECT, JSON.stringify(project))
    state.currentProject = project
  },

  setCurrentDashboardPeriod({ dates, name }) {
    state.currentDashboardPeriod = { dates, name }
  },

  setCurrentProjectPeriod({ dates, name }) {
    state.currentProjectPeriod = { dates, name }
  },

  /** @param {type: "customers" | "projects" | "dashboard" | "deals" } */
  setCurrentActivitiesPeriod({ dates, name, type }) {
    state.currentActivitiesPeriod[type] = { dates, name }
  },

  /** @param {type: "customers" | "projects" | "dashboard" | "deals" }
   *  @param {status: "IS_NULL" | "IS_NOT_NULL" | null}
   */
  setCurrentActivitiesStatus({ status, type }) {
    state.currentActivitiesStatus[type] = { status }
  },

  setShowClosedProjects(show) {
    state.showClosedProjects = show
  },

  setProjectsDropdownOption(option, selectedProjects = []) {
    state.projectDropdownOption = option
    localStorage.setItem(LOCAL_STORAGE_PROJECT_DROPDOWN_OPTION, option)
    if (option === PROJECT_DROPDOWN_OPTIONS.SELECTED) {
      state.projectDropdownSelection = selectedProjects
      localStorage.setItem(LOCAL_STORAGE_PROJECT_DROPDOWN_SELECTION, JSON.stringify(selectedProjects))
    }
  },

  setDisabledHistoricalCheckboxMessage(message) {
    state.disabledHistoricalCheckboxMessage = message
  },

  // Used for displaying loading indicator in queries defined outside of vue context
  setExceptionalLoading(isLoading) {
    if (isLoading) {
      state.exceptionalLoadingCount++
    } else {
      if (state.exceptionalLoadingCount > 0) {
        state.exceptionalLoadingCount--
      }
    }
  },

  // Used for displaying loading indicator in mutations defined outside of vue context
  setExceptionalUpdating(isUpdating) {
    if (isUpdating) {
      state.exceptionalUpdatingCount++
    } else {
      if (state.exceptionalUpdatingCount > 0) {
        state.exceptionalUpdatingCount--
      }
    }
  },

  setSidebarExpanded() {
    localStorage.setItem("sidebar-expanded", String(!state.isSidebarExpanded))
    state.isSidebarExpanded = !state.isSidebarExpanded
  }
}

const getters = {
  isSnackbarVisible: computed(() => Boolean(state.snackbarMessage)),
  isSidebarExpanded: computed(() => state.isSidebarExpanded === true),
  isModalOpened: computed(() => Boolean(state.openedModalWindow?.name))
}

export default {
  state,
  methods,
  getters
}
