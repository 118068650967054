<template>
  <div class="flex text-start flex-col space-y-4 p-4">
    <slot name="title"></slot>

    <div class="space-y-4">
      <div class="flex space-x-4">
        <div class="flex flex-col space-y-2">
          <label class="label"> Date </label>

          <div>
            <DatePicker
              v-model="activityData.date"
              model-type="yyyy-MM-dd"
              :error="v$.date.$error"
              class="weight-normal w-[180px]"
              placeholder="Select date"
              clearable
              :disabled="!canUpdate"
            />
            <p v-if="v$.date.$error" class="text-sm text-red-700">
              {{ v$.date.$errors[0].$message }}
            </p>
          </div>
        </div>

        <div class="flex flex-col space-y-2">
          <label class="label"> Start </label>

          <div>
            <DatePicker
              v-model="activityData.startTime"
              :error="v$.startTime.$error"
              class="weight-normal"
              placeholder="HH:mm"
              time-picker
              clearable
              text-input
              :disabled="!activityData.date || !canUpdate"
              :maxTime="activityData.endTime"
            />
            <p v-if="v$.startTime.$error" class="text-sm text-red-700">
              {{ v$.startTime.$errors[0].$message }}
            </p>
          </div>
        </div>

        <div class="flex flex-col space-y-2">
          <label class="label"> End </label>
          <div>
            <DatePicker
              v-model="activityData.endTime"
              :error="v$.endTime.$error"
              class="weight-normal"
              placeholder="HH:mm"
              time-picker
              clearable
              text-input
              :disabled="!activityData.date || !canUpdate"
              :minTime="activityData.startTime"
            />
            <p v-if="v$.endTime.$error" class="text-sm text-red-700">
              {{ v$.endTime.$errors[0].$message }}
            </p>
          </div>
        </div>
      </div>

      <div class="flex justify-center space-x-2 pb-2">
        <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Cancel</button>
        <button class="button" @click="updateActivities" :disabled="isLoading" data-test="confirm-btn">Save</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from "vue"
import { useMutation, useQuery } from "@vue/apollo-composable"
import { useVuelidate } from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import DatePicker from "@/components/BaseDatePicker"
import UPDATE_ACTIVITIES_MUTATION from "@/containers/Activity/activity-editing-mutation.gql"
import ACTIVITY_QUERY from "@/containers/Activity/activity-by-id-query.gql"

const props = defineProps({
  payload: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(["loading"])
const store = inject("store")

const activityIds = computed(() => props.payload.activityIds || [])

const activityData = ref({
  date: null,
  startTime: null,
  endTime: null
})

const rules = {
  date: { required },
  startTime: {},
  endTime: {}
}
const v$ = useVuelidate(rules, {
  date: computed(() => activityData.value.date),
  startTime: computed(() => activityData.value.startTime),
  endTime: computed(() => activityData.value.endTime)
})

const canUpdate = ref(true)

const isLoading = ref(false)

const { mutate: updateActivitiesMutation } = useMutation(UPDATE_ACTIVITIES_MUTATION)

// Function to combine date and time
const combineDateAndTime = (date, time) => {
  if (!date || !time) return null

  if (typeof time === "object") {
    const hours = time.hours.toString().padStart(2, "0")
    const minutes = time.minutes.toString().padStart(2, "0")
    return `${date} ${hours}:${minutes}:00`
  }

  return `${date} ${time}`
}

// Get activity details and update it
const getActivityAndUpdate = async (activityId) => {
  return new Promise((resolve, reject) => {
    const { onResult, onError } = useQuery(ACTIVITY_QUERY, { activityId })

    onResult(({ data }) => {
      if (data && data.saleActivity) {
        const activity = data.saleActivity

        const input = {
          id: activity.id,
          date: activityData.value.date,
          start_time: activityData.value.startTime
            ? combineDateAndTime(activityData.value.date, activityData.value.startTime)
            : null,
          end_time: activityData.value.endTime
            ? combineDateAndTime(activityData.value.date, activityData.value.endTime)
            : null,
          sale_activity_type_id: activity.saleActivityType.id
        }

        if (activity.deals && activity.deals.length > 0) {
          input.deal_id = activity.deals[0].id
        } else if (activity.customers && activity.customers.length > 0) {
          input.customer_id = activity.customers[0].id
        } else {
          if (activity.user && activity.user.id) {
            input.customer_id = activity.user.id
          }
        }

        if (activity.dealContacts && activity.dealContacts.length > 0) {
          input.deal_contact_id = activity.dealContacts[0].id
        }

        resolve(input)
      } else {
        reject(new Error("Activity not found"))
      }
    })

    onError((error) => {
      console.error("Error fetching activity:", error)
      reject(error)
    })
  })
}

// Update activities with the new date
const updateActivities = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) {
    return
  }

  isLoading.value = true
  emit("loading", true)

  try {
    for (const id of activityIds.value) {
      try {
        const activityInput = await getActivityAndUpdate(id)

        await updateActivitiesMutation({
          input: { ...activityInput }
        })
      } catch (error) {
        console.error(`Error updating activity ${id}:`, error)
        throw error
      }
    }

    store.ui.methods.setSnackbarMessage({
      message: `Successfully updated ${activityIds.value.length} ${
        activityIds.value.length === 1 ? "activity" : "activities"
      }`,
      type: "success"
    })

    if (props.payload.onComplete) {
      props.payload.onComplete()
    }

    closeModal()
  } catch (error) {
    console.error("Error updating activities:", error)
    store.ui.methods.setSnackbarMessage({
      message: "Failed to update activities. Please try again.",
      type: "error"
    })
  } finally {
    isLoading.value = false
    emit("loading", false)
  }
}

// Close the modal
const closeModal = () => {
  store.ui.methods.setModalWindowState()
}
</script>
