<template>
  <div class="flex flex-col space-y-5 text-start p-4">
    <slot name="title"></slot>
    <div v-if="dealInfo.companyName && dealInfo.projectName" class="text-sm text-center text-gray-600 -mt-4 mb-2">
      {{ dealInfo.companyName }} - {{ dealInfo.projectName }}
    </div>

    <div class="flex mb-2 items-baseline">
      <label class="label break-word w-1/4">Responsible</label>

      <div class="flex-col w-3/4">
        <select
          class="input"
          :value="activityData.userId"
          :class="{ 'input--invalid': v$.userId?.$error }"
          :disabled="members?.length === 1"
          @change="onMemberSelect"
          data-test="user-id"
        >
          <option v-for="(member, i) in members" :key="i" :value="member.value">
            {{ member.title }}
          </option>
        </select>

        <p v-if="v$.userId.$error" class="text-sm text-red-700">
          {{ v$.userId.$errors[0].$message }}
        </p>
      </div>
    </div>

    <div class="flex items-center mb-2">
      <label class="label break-word w-1/4"> Date </label>

      <div class="w-3/4">
        <DatePicker
          v-model="activityData.date"
          model-type="yyyy-MM-dd"
          :error="v$.date.$error"
          class="weight-normal w-full"
          placeholder="Select date"
          clearable
        />
        <p v-if="v$.date.$error" class="text-sm text-red-700">
          {{ v$.date.$errors[0].$message }}
        </p>
      </div>
    </div>

    <div class="flex justify-center space-x-2 pb-2">
      <button class="button button-bg-light" @click="closeModal" data-test="cancel-btn">Cancel</button>
      <button class="button" @click="onAddFollowUpActivity" data-test="confirm-btn">Save</button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, reactive, nextTick } from "vue"
import { DateTime } from "luxon"
import { useVuelidate } from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { useMutation } from "@vue/apollo-composable"
import { SNACKBAR_MESSAGE_TYPES } from "@/constants"
import DatePicker from "@/components/BaseDatePicker/index.vue"
import CREATE_FOLLOW_UP_ACTIVITY from "./create-follow-up-activity-mutation.gql"

const store = inject("store")

const props = defineProps({
  payload: {
    type: Object,
    default: () => ({
      members: null,
      dealIds: [],
      dealInfo: {},
      insertActivity: () => {}
    })
  }
})

const members = computed(() => props.payload.members)
const dealIds = computed(() => props.payload.dealIds)
const dealInfo = computed(() => props.payload.dealInfo)

const activityData = reactive({
  userId: members.value?.length === 1 ? members.value[0]?.value : null,
  date: DateTime.now().toISODate()
})

const rules = computed(() => ({
  date: { required },
  userId: { required }
}))
const v$ = useVuelidate(rules, activityData)

const { mutate: createFollowUpActivity } = useMutation(CREATE_FOLLOW_UP_ACTIVITY, () => ({
  throws: "never"
}))

const onMemberSelect = (member) => {
  activityData.userId = member.target.value
}

const onAddFollowUpActivity = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return

  const response = await createFollowUpActivity({
    input: {
      date: activityData.date,
      deal_ids: dealIds.value,
      user_id: activityData.userId
    }
  })

  if (response?.data?.createBulkFollowUpSaleActivities) {
    props.payload.insertActivity(response?.data?.createBulkFollowUpSaleActivities)

    nextTick(() => {
      store.ui.methods.setSnackbarMessage({
        message: `Successfully created Follow-Up ${dealIds.value.length > 1 ? "activities" : "activity"}`,
        type: SNACKBAR_MESSAGE_TYPES.SUCCESS
      })
    })

    closeModal()
  }
}

const closeModal = () => {
  store.ui.methods.setModalWindowState()
}
</script>
