export default {
  BLANK: "No date set",
  TODAY: "Today",
  WEEK: "This week",
  NEXT_WEEK: "Next week",
  LAST_WEEK: "Last week",
  MONTH: "This month",
  YEAR: "This year",
  ALL: "All",
  CUSTOM: "Custom"
}
