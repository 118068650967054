const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ "@/containers/Dashboard")
const Sidebar = () => import(/* webpackChunkName: "Sidebar" */ "@/components/TheSidebar")
const Header = () => import(/* webpackChunkName: "Header" */ "@/components/TheHeader")
const DashboardHeaderTitle = () =>
  import(/* webpackChunkName: "DashboardHeaderTitle" */ "@/containers/Dashboard/DashboardHeaderTitle")
const DashboardStatus = () => import(/* webpackChunkName: "DashboardStatus" */ "./Status")
const DashboardDealsTable = () => import(/* webpackChunkName: "DashboardDealsTable" */ "./Deals/DealsTable")
const DealGeneral = () => import(/* webpackChunkName: "DealGeneral" */ "@/containers/Deal/General")
const DealActivitiesTable = () =>
  import(/* webpackChunkName: "DealActivitiesTable" */ "@/containers/Deal/Activities/ActivitiesTable")
const DealStand = () => import(/* webpackChunkName: "DealStand" */ "@/containers/Deal/Stand")
const DashboardActivitiesTable = () =>
  import(/* webpackChunkName: "DashboardActivitiesTable" */ "./Activities/ActivitiesTable")
const Activity = () => import(/* webpackChunkName: "Activity" */ "@/containers/Activity")
const ActivityHeaderTitle = () =>
  import(/* webpackChunkName: "ActivityHeaderTitle" */ "@/containers/Activity/ActivityHeaderTitle")
const DealHeaderTitle = () => import(/* webpackChunkName: "DealHeaderTitle" */ "@/containers/Deal/DealHeaderTitle")
const DealExtraOrders = () => import(/* webpackChunkName: "DealServices" */ "@/containers/Deal/ExtraOrders")
const DealHistory = () => import(/* webpackChunkName: "DealHistory" */ "@/containers/Deal/History")

export default {
  path: "/dashboard",
  name: "dashboard",
  components: {
    default: Dashboard,
    header: Header,
    sidebar: Sidebar
  },
  meta: {
    navigationTabsKey: "dashboard"
  },
  redirect: { name: "dashboard-status" },
  children: [
    {
      path: "status",
      name: "dashboard-status",
      components: {
        default: DashboardStatus,
        dynamicHeaderTitle: DashboardHeaderTitle
      },
      meta: {
        requiredHeaderDynamicComponents: ["DashboardUsersDropdown", "DashboardProjectsDropdown"],
        breadcrumb: "my status"
      }
    },
    {
      path: "deals",
      name: "dashboard-deals",
      redirect: { name: "dashboard-deals-table" },
      meta: {
        breadcrumb: "my deals"
      },
      children: [
        {
          path: "",
          name: "dashboard-deals-table",
          components: {
            default: DashboardDealsTable,
            dynamicHeaderTitle: DashboardHeaderTitle
          },
          meta: {
            requiredHeaderDynamicComponents: ["DashboardUsersDropdown", "DashboardProjectsDropdown"]
          }
        },
        {
          path: ":dealId",
          name: "dashboard-deal",
          meta: {
            dynamicHeader: "companyName",
            navigationTabsKey: "dashboard-deal",
            requiredHeaderDynamicComponents: ["CurrentProject"]
            /* checkPermissionToOperation: {
              withinTeam: true,
              operation: "view",
              model: "Deal",
              modelIdKey: "dealId"
            } */
          },
          redirect: { name: "dashboard-deal-general" },
          children: [
            {
              path: "general",
              name: "dashboard-deal-general",
              props: true,
              components: {
                default: DealGeneral,
                dynamicHeaderTitle: DealHeaderTitle
              },
              meta: {
                breadcrumb: "deal general"
              }
            },
            {
              path: "activities",
              name: "dashboard-deal-activities",
              meta: {
                breadcrumb: "deal activities"
              },
              redirect: { name: "dashboard-deal-activities-table" },
              children: [
                {
                  path: "",
                  name: "dashboard-deal-activities-table",
                  props: true,
                  components: {
                    default: DealActivitiesTable,
                    dynamicHeaderTitle: DealHeaderTitle
                  }
                },
                {
                  path: ":activityId",
                  name: "dashboard-deal-activity",
                  props: true,
                  components: {
                    default: Activity,
                    dynamicHeaderTitle: ActivityHeaderTitle
                  },
                  meta: {
                    navigationTabsKey: null,
                    breadcrumb: "activity"
                  }
                }
              ]
            },
            {
              path: "stand",
              name: "dashboard-deal-stand",
              props: true,
              components: {
                default: DealStand,
                dynamicHeaderTitle: DealHeaderTitle
              },
              meta: {
                breadcrumb: "deal stand"
              }
            },
            {
              path: "extra-orders",
              name: "dashboard-deal-extra-orders",
              props: true,
              components: {
                default: DealExtraOrders,
                dynamicHeaderTitle: DealHeaderTitle
              },
              meta: {
                breadcrumb: "extra orders"
              }
            },
            {
              path: "history",
              name: "dashboard-deal-history",
              props: true,
              components: {
                default: DealHistory,
                dynamicHeaderTitle: DealHeaderTitle
              },
              meta: {
                breadcrumb: "history"
              }
            }
          ]
        }
      ]
    },
    {
      path: "activities",
      name: "dashboard-activities",
      meta: {
        requiredHeaderDynamicComponents: ["DashboardUsersDropdown", "DashboardProjectsDropdown"],
        breadcrumb: "my activities"
      },
      redirect: { name: "dashboard-activities-table" },
      children: [
        {
          path: "",
          name: "dashboard-activities-table",
          components: {
            default: DashboardActivitiesTable,
            dynamicHeaderTitle: DashboardHeaderTitle
          }
        },
        {
          path: ":activityId",
          name: "dashboard-activity",
          props: true,
          components: {
            default: Activity,
            dynamicHeaderTitle: ActivityHeaderTitle
          },
          meta: {
            navigationTabsKey: null,
            breadcrumb: "activity",
            requiredHeaderDynamicComponents: null
          }
        }
      ]
    }
  ]
}
