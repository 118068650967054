<template>
  <div class="absolute z-20 top-0 bottom-0 left-0 right-0 m-auto h-fit w-min overflow-hidden">
    <LoadingIndicator v-if="showLoading" />
    <div
      class="card-rounded min-w-[600px] max-w-[812px] max-h-[90vh] text-center overflow-y-auto"
      :style="{ height: openedModalWindow.height }"
    >
      <keep-alive :include="['CustomerSearching', 'DealCreation']">
        <component
          :is="components[openedModalWindow.name]"
          :payload="openedModalWindow.payload"
          @loading="loadingChanged"
        >
          <template #title>
            <p v-if="openedModalWindow.title" class="heading-2 text-center" data-test="modal-title">
              {{ openedModalWindow.title }}
            </p>
          </template>
        </component>
      </keep-alive>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, ref } from "vue"
import DeletingConfirmation from "@/components/TheModalWindow/DeletingConfirmation"
import TeamCreation from "@/components/TheModalWindow/TeamCreation"
import TeamEditing from "@/components/TheModalWindow/TeamEditing"
import MemberCreation from "@/components/TheModalWindow/MemberAdding"
import MemberEditing from "@/components/TheModalWindow/MemberEditing"
import ActivityCreation from "@/components/TheModalWindow/ActivityCreation"
import ContactPersonsEditing from "@/components/TheModalWindow/DealContactPersonsEditing"
import CustomerCardCompanyEditing from "@/components/TheModalWindow/CustomerCardCompanyEditing"
import CustomerCardContactEditing from "@/components/TheModalWindow/CustomerCardContactEditing"
import CustomerComment from "@/components/TheModalWindow/CustomerComment"
import DealsComments from "@/components/TheModalWindow/DealsComments"
import CustomerSearching from "@/components/TheModalWindow/CustomerSearching"
import CustomerSelecting from "@/components/TheModalWindow/CustomerSelecting"
import CustomerCreation from "@/components/TheModalWindow/CustomerCreation"
import CustomersMerging from "@/components/TheModalWindow/CustomersMerging"
import ConfirmDeclineCancelDeal from "@/components/TheModalWindow/ConfirmDeclineCancelDeal"
import WarningMessage from "@/components/TheModalWindow/WarningMessage"
import ExtraOrderCreatingEditing from "@/components/TheModalWindow/ExtraOrderCreatingEditing"
import DealCreation from "@/components/TheModalWindow/DealCreation"
import ProjectInvoicing from "@/components/TheModalWindow/ProjectInvoicing"
import TagCreationEditing from "@/components/TheModalWindow/TagCreationEditing"
import DealResponsible from "@/components/TheModalWindow/DealResponsible"
import ActivityFollowUpCreation from "@/components/TheModalWindow/ActivityFollowUpCreation"
import ActivitySetDateModal from "@/components/TheModalWindow/ActivitySetDateModal"
import LoadingIndicator from "@/components/BaseLoadingIndicator"
import DealUnsell from "@/components/TheModalWindow/DealUnsell"

import { MODAL_WINDOW_NAMES } from "@/constants"

const store = inject("store")
const showLoading = ref(false)

const components = {
  [MODAL_WINDOW_NAMES.CONFIRM_DELETING_MODAL]: DeletingConfirmation,
  [MODAL_WINDOW_NAMES.TEAM_CREATION_MODAL]: TeamCreation,
  [MODAL_WINDOW_NAMES.TEAM_EDITING_MODAL]: TeamEditing,
  [MODAL_WINDOW_NAMES.MEMBER_CREATION_MODAL]: MemberCreation,
  [MODAL_WINDOW_NAMES.MEMBER_EDITING_MODAL]: MemberEditing,
  [MODAL_WINDOW_NAMES.ACTIVITY_CREATION_MODAL]: ActivityCreation,
  [MODAL_WINDOW_NAMES.CONTACT_PERSONS_EDITING]: ContactPersonsEditing,
  [MODAL_WINDOW_NAMES.CUSTOMER_CARD_COMPANY_EDITING_MODAL]: CustomerCardCompanyEditing,
  [MODAL_WINDOW_NAMES.CUSTOMER_CARD_CONTACT_EDITING_MODAL]: CustomerCardContactEditing,
  [MODAL_WINDOW_NAMES.CUSTOMER_COMMENT_MODAL]: CustomerComment,
  [MODAL_WINDOW_NAMES.DEALS_COMMENTS_MODAL]: DealsComments,
  [MODAL_WINDOW_NAMES.DEAL_CREATION_MODAL]: DealCreation,
  [MODAL_WINDOW_NAMES.DEAL_UNSELL_MODAL]: DealUnsell,
  [MODAL_WINDOW_NAMES.CUSTOMER_SEARCH_MODAL]: CustomerSearching,
  [MODAL_WINDOW_NAMES.CUSTOMER_SELECT_MODAL]: CustomerSelecting,
  [MODAL_WINDOW_NAMES.CUSTOMER_CREATION_MODAL]: CustomerCreation,
  [MODAL_WINDOW_NAMES.CUSTOMERS_MERGING_MODAL]: CustomersMerging,
  [MODAL_WINDOW_NAMES.CONFIRM_DECLINE_CANCEL_DEAL_MODAL]: ConfirmDeclineCancelDeal,
  [MODAL_WINDOW_NAMES.WARNING_MESSAGE_MODAL]: WarningMessage,
  [MODAL_WINDOW_NAMES.EXTRA_ORDER_CREATING_EDITING_MODAL]: ExtraOrderCreatingEditing,
  [MODAL_WINDOW_NAMES.PROJECT_INVOICING_MODAL]: ProjectInvoicing,
  [MODAL_WINDOW_NAMES.TAG_CREATION_EDITING]: TagCreationEditing,
  [MODAL_WINDOW_NAMES.DEAL_RESPONSIBLE_MODAL]: DealResponsible,
  [MODAL_WINDOW_NAMES.ACTIVITY_FOLLOW_UP_CREATION]: ActivityFollowUpCreation,
  [MODAL_WINDOW_NAMES.ACTIVITY_SET_DATE_MODAL]: ActivitySetDateModal
}

const openedModalWindow = computed(() => store.ui.state.openedModalWindow)

const loadingChanged = (isLoading) => {
  showLoading.value = isLoading
}
</script>
